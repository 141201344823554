import React from "react";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import ScrollMemory from "react-router-scroll-memory";
import Home from "./Home";
import { cardsData } from "./constants";

const AppContainer = styled.div`
  background-color: #f4f4f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AppInner = styled.div`
  padding: 20px 25px;
  flex-grow: 1;
`;

const GlobalStyle = css`
  body {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
  }
  nav {
    display: flex;
    padding: 1rem;
    a {
      margin: 0.5rem;
    }
  }
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  padding: 20px 30px;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
    margin-right: 15px;
    font-size: 18px;
    margin: 0;
    &:hover {
      color: #f0f0f0;
    }
  }

  @media (max-width: 768px) {
    gap: 10px;
    padding: 10px 5px;
    flex-wrap: wrap;
  }
`;

const ExternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: auto;
  &:hover {
    color: #f0f0f0;
  }

  img {
    position: relative;
    top: 2px;
  }
  span {
    display: none;
  }
  @media (max-width: 768px) {
    margin: 0;
    img {
      display: none;
    }
    span {
      display: inline;
      text-decoration: underline;
      margin-right: 2px;
    }
  }
`;

const Breadcrumb = styled.div`
  font-size: 16px;
  color: #fff;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = styled.footer`
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 30px;
  a {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
    &:hover {
      color: #f0f0f0;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    a {
      margin: 5px 0;
    }
  }
`;

const App = ({ location }) => {
  const currentRoute = cardsData.find((card) => card.route === location.pathname);

  return (
    <AppContainer>
      <Route
        path="/"
        render={() => (
          <Navigation>
            <Breadcrumb>
              <Link to="/">Demos </Link>
              {currentRoute && currentRoute.route !== "/" && (
                <>
                  /&nbsp;
                  <Link to={currentRoute.route}>{currentRoute.breadcrumb}</Link>
                </>
              )}
            </Breadcrumb>

            <ExternalLink href="https://reactivesearch.io">
              <img
                src="https://framerusercontent.com/images/5O9DdYA4mxkaib8dC4Ksoca5Ulo.png?scale-down-to=512"
                alt="ReactiveSearch.io"
                height={50}
              />{" "}
              <span>ReactiveSearch.io</span> ↗️
            </ExternalLink>
          </Navigation>
        )}
      />
      <Global styles={GlobalStyle} />
      <ScrollMemory />
      <AppInner>
        <Switch>
          <Route exact path="/" component={Home} />
          {cardsData.filter((_) => _.route).map((card) => (
            <Route key={card.route} path={card.route} component={card.component} />
          ))}
        </Switch>
      </AppInner>
    <Footer>
        <a href="https://opensource.reactivesearch.io" target="_blank" rel="noreferrer">ReactiveSearch UI kit ↗️</a>
        <a href="https://github.com/appbaseio/reactivesearch" target="_blank" rel="noreferrer">ReactiveSearch UI on Github ↗️</a>
        <a href="https://github.com/appbaseio/reactivesearch-api" target="_blank" rel="noreferrer">ReactiveSearch API server on Github ↗️</a>
    </Footer>
    </AppContainer>
  );
};

export default withRouter(App);

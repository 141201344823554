import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const CardLink = ({ card, className, children }) => {
  if (card.route) {
    return (
      <Link to={card.route} className={className}>
        {children}
      </Link>
    );
  }
  return (
    <a
      href={card.link}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};

const Card = styled(CardLink)`
  & > .card-title {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 0 0 10px 10px; // Rounded corners at the bottom
    text-align: center;
    box-sizing: border-box;
  }

  position: relative;
  width: 400px;
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 600px) {
    width: 300px;
    height: 225px; // Maintains the 4:3 aspect ratio
  }
`;

export default Card;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import { FaUsers, FaRocket, FaExternalLinkAlt, FaTimes } from "react-icons/fa";

import {
  ReactiveBase,
  ToggleButton,
  MultiDropdownList,
  ReactiveList,
  SearchBox,
  SelectedFilters,
  MultiList,
  MultiRange
} from "@appbaseio/reactivesearch";

// Global styles using @emotion/styled
const GlobalStyle = css`
  body,
  html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;

    .result-list-container {
      margin-bottom: 4rem;
    }
  }
`;

// Styled components for layout
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 15px;
  border-right: 1px solid #ccc;
  &:first-child {
    max-width: 500px;
  }
  &:last-child {
    border-right: none;
    background: #fafafa;
  }
`;

const FilterToggle = styled.button`
  display: none; // Hide the toggle button on desktop
  @media (max-width: 767px) {
    display: block; // Show the toggle button on smaller screens
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px 20px;
    background-color: #4183c4;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
  }
`;

// Styled components
const CompanyCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
`;

const CompanyLogo = styled.img`
  width: 100px;
  height: auto;
  margin-right: 20px;
`;

const CompanyInfo = styled.div`
  flex-grow: 1;
  p {
    margin: 0;
  }
`;

const CompanyName = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  color: #333;
`;

// Styled components for tags
const Tag = styled.span`
  background-color: #4183c4; // Example background color
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-right: 5px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
`;

// Modify the CompanyMeta component to include tags
const CompanyMeta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap; // Allow tags to wrap
  margin: 1rem 0 8px;
  gap: 10px;
`;

const MetaSpan = styled.span`
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 0.9rem;
  color: #666;
  svg {
    margin-right: 5px;
    color: #4183c4;
  }
`;

const StyledAnchor = styled.a`
  color: #4183c4;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-left: 5px;
  }
`;

// Styled components for the filter drawer
const FilterDrawer = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 11;
    padding: 20px;
    height: 100%;
    padding-bottom: 70px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; // Add transitions for opacity and visibility
    opacity: ${({ show }) => (show ? 1 : 0)}; // Control opacity
    visibility: ${({ show }) =>
      show ? "visible" : "hidden"}; // Control visibility

    h2 {
      margin-top: 0;
    }
  }
`;

const ApplyButton = styled.button`
  @media (min-width: 768px) {
    display: none;
  }
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #4183c4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const FilterTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  padding: 10px 0;
`;

const FilterTag = styled.span`
  background-color: #4183c4; // Example background color
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-right: 5px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const FilterLabel = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const StyledToggleButton = styled(ToggleButton)`
  & a.button-cls {
    text-decoration: none;
  }
`;

const ECommerce = () => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const applyFilters = () => {
    setShowFilters(false);
  };

  const companiesReactiveList = data => {
    return (
      <CompanyCard key={data.id}>
        <CompanyLogo src={data.small_logo_thumb_url} alt={data.name} />
        <CompanyInfo>
          <CompanyName>{data.name}</CompanyName>
          <p>{data.one_liner}</p>
          <CompanyMeta>
            <MetaSpan>
              <FaUsers /> Team size: {data.team_size}
            </MetaSpan>
            <MetaSpan>
              <FaRocket /> Stage: {data.stage}
            </MetaSpan>
          </CompanyMeta>
          {data.industries.map(industry => (
            <Tag key={industry}>{industry}</Tag>
          ))}
          <StyledAnchor
            href={data.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Website <FaExternalLinkAlt />
          </StyledAnchor>
        </CompanyInfo>
      </CompanyCard>
    );
  };

  return (
    <ReactiveBase
      app="yc-companies-dataset"
      url="https://a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61@appbase-demo-ansible-abxiydt-arc.searchbase.io?preference=lded"
    >
      <Global styles={GlobalStyle} />
      <Row>
        <Column show={showFilters}>
          <FilterDrawer show={showFilters}>
            <h2>Filter Options</h2>

            <StyledToggleButton
                innerClass={{
                    "button": "button-cls"
                }}
                componentId="Status"
                title="Status"
                dataField="status.keyword"
                data={[
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" },
                    { label: "Acquired", value: "Acquired" },
                    { label: "Public", value: "Public" },
                ]}
            />
            <MultiDropdownList
              title="Choose Regions"
              componentId="Region"
              dataField="regions.keyword"
              size={100}
              placeholder="Select regions"
              react={{ and: ["Search"] }}
            />
            <br />
            <MultiList
              title="Choose Industries"
              componentId="Industry"
              dataField="industries.keyword"
              size={100}
              placeholder="Select industries"
              react={{ and: ["Search"] }}
            />
            <br />
            <MultiRange
              data={[
                { start: 1, end: 10, label: "1 - 10" },
                { start: 11, end: 100, label: "11 - 100" },
                { start: 101, end: 500, label: "101 - 500" },
                { start: 501, end: 10000, label: ">500" }
              ]}
              title="Select team size"
              componentId="Team_Size"
              dataField="team_size"
              react={{ and: ["Search"] }}
              filterLabel="Team Size"
            />
            <ApplyButton onClick={applyFilters}>Apply Filters</ApplyButton>
          </FilterDrawer>
        </Column>

        <Column>
          <SearchBox
            componentId="Search"
            dataField={[
              "name",
              "name.search",
              "long_description",
              "long_description.search",
              "one_liner",
              "one_liner.search"
            ]}
            fieldWeights={[3, 1, 1, 1, 2, 1]}
            autosuggest={false}
            placeholder="Search for a company"
          />{" "}
          <br />
          <SelectedFilters
            render={props => {
              const { selectedValues, setValue } = props;
              const clearFilter = component => {
                setValue(component, null);
              };

              const filters = Object.keys(selectedValues).map(component => {
                const {
                  value,
                  label,
                  componentType,
                  showFilter
                } = selectedValues[component];
                if (!value || value.length === 0 || !showFilter) return null;
                let processesValue;
                if (Array.isArray(value) && value.length) {
                  if (componentType === "MULTIRANGE") {
                    processesValue = value.map(_ => _.label).join(", ");
                  } else if (componentType === "TOGGLEBUTTON") {
                    processesValue = value.map(_ => _.label).join(", ")
                  } else {
                    processesValue = value.join(", ");
                  }
                } else {
                  processesValue = value;
                }

                return (
                  <FilterTag
                    key={component}
                    onClick={() => clearFilter(component)}
                  >
                    <FilterLabel>{label}:</FilterLabel> {processesValue}&nbsp;
                    <FaTimes />
                  </FilterTag>
                );
              });

              return <FilterTagsContainer>{filters}</FilterTagsContainer>;
            }}
          />
          <br />
          <ReactiveList
            componentId="SearchResult"
            defaultQuery={() => ({
                sort: {
                    _score: { order: "desc" },
                    team_size: { order: "desc" }
                }
            })}
            includeFields={["name", "one_liner", "team_size", "stage", "industries", "website", "small_logo_thumb_url"]}
            dataField="name"
            className="result-list-container"
            size={5}
            renderItem={companiesReactiveList}
            pagination
            react={{ and: ["Industry", "Search", "Region", "Team_Size", "Status"] }}
          />
        </Column>
      </Row>
      <FilterToggle onClick={toggleFilters}>
        {showFilters ? "Hide Filters" : "Show Filters"}
      </FilterToggle>
    </ReactiveBase>
  );
};

export default ECommerce;

import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import axios from "axios";
import { FaUsers, FaRocket, FaExternalLinkAlt } from "react-icons/fa";

// Global styles using @emotion/styled
const GlobalStyle = css`
  body,
  html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;

    .result-list-container {
      margin-bottom: 4rem;
    }
  }
`;

// Styled components for layout
const FullWidthContainer = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;
`;

const CenteredMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #666;
`;

const StatsMessage = styled.div`
  text-align: left;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: #888;
`;

// Styled components
const CompanyCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
`;

const CompanyLogo = styled.img`
  width: 100px;
  height: auto;
  margin-right: 20px;
`;

const CompanyInfo = styled.div`
  flex-grow: 1;
  p {
    margin: 0;
  }
`;

const CompanyName = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  color: #333;
`;

// Styled components for tags
const Tag = styled.span`
  background-color: #4183c4; // Example background color
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-right: 5px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
`;

// Modify the CompanyMeta component to include tags
const CompanyMeta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap; // Allow tags to wrap
  margin: 1rem 0 8px;
  gap: 10px;
`;

const MetaSpan = styled.span`
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 0.9rem;
  color: #666;
  svg {
    margin-right: 5px;
    color: #4183c4;
  }
`;

const StyledAnchor = styled.a`
  color: #4183c4;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-left: 5px;
  }
`;

const KNN = () => {
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchTime, setSearchTime] = useState(0);
  const debounceRef = useRef(null);

  const randomInputs = [
    "Semantic search API",
    "issue detection for oil rigs",
    "Browser based IDE",
    "patient management CRM",
    "open source EHR software",
    "RAG for contract search",
    "gene editing diagnostics",
    "military defense technology",
    "protein-based food technology",
    "AI-powered customer support",
    "autonomous vehicle software",
    "blockchain for supply chain",
    "cloud-native application monitoring",
    "cybersecurity threat detection",
    "digital health platforms",
    "IoT device management",
    "renewable energy solutions",
    "smart home automation",
    "voice recognition software",
    "wearable fitness technology"
];;

  const handleSearch = async (input) => {
    if (!input) return;
    setLoading(true);
    setNoResults(false);
    try {
      const response = await axios.post(
        "https://appbase-demo-ansible-abxiydt-arc.searchbase.io/_knn_search/_reactivesearch",
        {
          query: [
            {
              id: "search",
              value: input,
              vectorDataField: "vector_data"
            }
          ]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa("a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61")}`
          }
        }
      );
      const data = response.data.hits.hits;
      setResults(data);
      setSearchTime(response.data.took);
      if (data.length === 0) {
        setNoResults(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      handleSearch(searchInput);
    }, 100);
  }, [searchInput]);

  const companiesReactiveList = (data) => {
    const { _source: company } = data;
    return (
      <CompanyCard key={company.id}>
        <CompanyLogo src={company.small_logo_thumb_url} alt={company.name} />
        <CompanyInfo>
          <CompanyName>{company.name}</CompanyName>
          <p>{company.one_liner}</p>
          <CompanyMeta>
            <MetaSpan>
              <FaUsers /> Team size: {company.team_size}
            </MetaSpan>
            <MetaSpan>
              <FaRocket /> Stage: {company.stage}
            </MetaSpan>
          </CompanyMeta>
          {company.industries.map((industry) => (
            <Tag key={industry}>{industry}</Tag>
          ))}
          <StyledAnchor
            href={company.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Website <FaExternalLinkAlt />
          </StyledAnchor>
        </CompanyInfo>
      </CompanyCard>
    );
  };

  return (
    <FullWidthContainer>
      <Global styles={GlobalStyle} />
      <Input
        type="text"
        placeholder="Search for a company"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <Button onClick={() => setSearchInput(randomInputs[Math.floor(Math.random() * randomInputs.length)])}>
        Try random search input
      </Button>
      {loading && <CenteredMessage>Loading...</CenteredMessage>}
      {!loading && searchTime > 0 && (
        <StatsMessage>Found top {results.length} results of 4,704 docs in {searchTime}ms</StatsMessage>
      )}
      {!loading && noResults && (
        <CenteredMessage>No search results are found, try another input</CenteredMessage>
      )}
      <div className="result-list-container">
        {results.map((result) => companiesReactiveList(result))}
      </div>
    </FullWidthContainer>
  );
};

export default KNN;

import React from "react";
import styled from "@emotion/styled";
import { cardsData } from "./constants";
import Card from "./components/Card";


const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin-top: 20px;
  gap: 1rem;
  max-width: 1600px;
  margin:auto;
`;

export default () => (
  <>
    <CardContainer>
      {cardsData.map(card => (
        <Card key={card.title} card={card} bgImage={card.bgImage}>
          <div className="card-title"> {card.title}</div>
        </Card>
      ))}
    </CardContainer>
  </>
);

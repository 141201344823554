import React from "react";
import styled from "@emotion/styled";

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const  Geo = () => (
  <div style={{ height: "90vh" }}>
    <StyledIframe
      src="https://codesandbox.io/embed/ivbyh?view=preview&module=%2Fsrc%2Findex.js&hidenavigation=1"
      title="Geo Search"
      allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
      sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    />
  </div>
);
export default Geo;